<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section title="비밀번호 변경" class="page-section--first page-section--last">
            <table-primary caption>
                <table-row title="현재 비밀번호" pointer>
                    <v-password-field v-model="form.passwordCurrent" placeholder="현재 비밀번호를 입력하세요." outlined hide-details />
                </table-row>
                <table-row title="새 비밀번호" pointer>
                    <v-password-field v-model="form.password" placeholder="새 비밀번호를 입력하세요.(6자 이상. 영문대문자, 소문자, 숫자 포함)" outlined hide-details />
                </table-row>
                <table-row title="새 비밀번호 재확인" pointer>
                    <v-password-field v-model="form.passwordConfirm" placeholder="새 비밀번호를 다시 입력하세요." outlined hide-details />
                </table-row>
            </table-primary>

            <!-- 하단버튼 -->
            <div class="v-btn--group">
                <v-row justify="center" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn large outlined rounded color="grey lighten-4" @click="$router.go(-1)">
                            <span class="grey--text">취소</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn large rounded dark color="secondary" @click="save">
                            <span>수정하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";
import TableRow from "@/components/dumb/table-row.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        PageSection,
        TablePrimary,
        TableRow,
        VPasswordField,
    },
    data: () => ({
        form: {
            password: null,
            passwordConfirm: null,
            passwordCurrent: null,
        },

        loading: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                }
            } finally {
                this.loading = false;
            }
        },

        async save() {
            try {
                this.validate();
            } catch (error) {
                alert(error.message);
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                ////////////////////////////////////////////////////////////////
                // 비밀번호 확인
                ////////////////////////////////////////////////////////////////
                let { password, passwordCurrent } = this.form;
                password = CryptoAES.encrypt(password);
                passwordCurrent = CryptoAES.encrypt(passwordCurrent);
                await api.v1.me.verify({ password: passwordCurrent });

                await api.v1.me.put({ password });

                alert("수정되었습니다");
                this.$router.go();
            } finally {
                this.loading = false;
            }
        },

        validate() {
            const { password, passwordConfirm, passwordCurrent } = this.form;

            if (!password || !passwordConfirm || !passwordCurrent) {
                throw new Error("비밀번호를 입력해주세요");
            }

            if (password != passwordConfirm) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");

            this.checkPassword(password);
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");
            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");
        },
    },
};
</script>
