var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    attrs: {
      "content-class": "v-dialog--primary"
    },
    on: {
      "input": _vm.emit,
      "change": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, 'v-dialog', Object.assign({}, _vm.$attrs, {
    value: _vm.value
  }), false), [_c('div', {
    staticClass: "v-dialog--primary__head"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        return _vm.emit(false);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "v-dialog--primary__body"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }