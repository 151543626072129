<template>
    <v-dialog v-bind="{ ...$attrs, value }" @input="emit" @change="emit" content-class="v-dialog--primary">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div class="v-dialog--primary__head">
            <v-spacer />
            <v-btn text icon tile @click="emit(false)">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="v-dialog--primary__body">
            <slot />
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
    },
    methods: {
        emit(value) {
            this.$emit("input", value);
        },
    },
};
</script>

<style>
</style>