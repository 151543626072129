<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section title="회원정보" class="page-section--first page-section--last">
            <table-primary caption>
                <table-row title="아이디" pointer>
                    {{ form.username }}
                </table-row>
                <table-row title="이름" pointer>
                    <v-text-field v-model="form.name" placeholder="이름을 입력하세요." outlined hide-details />
                </table-row>
                <table-row title="연락처" pointer>
                    <v-phone-field v-model="form.phone" @input="emit" />
                </table-row>
                <table-row title="이메일" pointer>
                    <v-email-field v-model="form.email" @input="emit" />
                </table-row>

                <template v-if="form.type == USER_TYPES.ORGANIZATION">
                    <table-row title="회사명" pointer>
                        <v-text-field v-model="form.companyName" type="text" class="input" outlined hide-details @input="emit" />
                    </table-row>
                    <table-row title="사업자등록번호" pointer>
                        <v-text-field v-model="form.businessNumber" type="text" class="input" outlined hide-details @input="emit" />
                    </table-row>
                    <table-row title="사업자등록증" pointer>
                        <v-file-input v-model="form.businessRegistration" placeholder="파일을 첨부하세요." outlined hide-details @change="emit" />
                    </table-row>
                </template>

                <!-- S::추가필드 -->
                <table-row title="보험증서">
                    <v-file-input v-model="form.blacksmith.insurance.policies[0]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details @change="emit">
                        <template #append>
                            <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[0]" @click="pullInsurancePolicy(0)">close</v-icon> </v-fade-transition>
                        </template>
                    </v-file-input>
                    <template v-if="form.blacksmith.insurance.policies[0]">
                        <v-file-input v-model="form.blacksmith.insurance.policies[1]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details class="mt-10" @change="emit">
                            <template #append>
                                <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[1]" @click="pullInsurancePolicy(1)">close</v-icon> </v-fade-transition>
                            </template>
                        </v-file-input>
                    </template>
                    <template v-if="form.blacksmith.insurance.policies[1]">
                        <v-file-input v-model="form.blacksmith.insurance.policies[2]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details class="mt-10" @change="emit">
                            <template #append>
                                <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[2]" @click="pullInsurancePolicy(2)">close</v-icon> </v-fade-transition>
                            </template>
                        </v-file-input>
                    </template>
                    <small>이미지 파일(JPG, PNG, GIF) PDF로 업로드 가능합니다.</small>
                    <dialog-cert-example />
                </table-row>
                <table-row title="보험 만기일">
                    <v-text-field v-model="form.blacksmith.insurance.expiresAt" type="date" placeholder="보험만기일을 선택하세요." outlined hide-details @input="emit" />
                </table-row>
                <table-row title="장비이용 동의서">
                    <v-file-input v-model="form.blacksmith.equipmentUseConsent" placeholder="파일을 첨부하세요." outlined hide-details @change="emit" />
                    <small>이미지 파일(JPG, PNG, GIF) PDF로 업로드 가능합니다.</small>
                    <equipment-agreement-download />
                </table-row>
                <table-row title="사용가능 장비">
                    <template v-if="!form?.blacksmith?.equipments__rentable?.length">
                        <div>사용가능한 장비가 없습니다. 장비운영교육 이수가 필요합니다.</div>
                    </template>
                    <template v-for="equipment in form?.blacksmith?.equipments__rentable || []">
                        <div :key="equipment._id">
                            {{ equipment.name }}
                            ({{ equipment.code }})
                        </div>
                    </template>
                </table-row>
                <!-- E::추가필드 -->
            </table-primary>

            <!-- 회원탈퇴 -->
            <v-layout justify-end class="mt-16">
                <v-btn to="/mypage/leave" outlined color="grey lighten-3" class="h-40px">
                    <span class="grey--text font-size-14">회원탈퇴</span>
                </v-btn>
            </v-layout>

            <!-- 하단버튼 -->
            <div class="v-btn--group">
                <v-row justify="center" class="row--x-small">
                    <v-col cols="auto">
                        <v-btn large outlined rounded color="grey lighten-4" @click="$router.go(-1)">
                            <span class="grey--text">취소</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn large rounded dark color="secondary" @click="save">
                            <span>수정하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
        <v-fade-transition>
            <v-overlay v-show="loading" light color="white" opacity="0.2">
                <v-progress-circular size="100" width="5" indeterminate color="primary" />
            </v-overlay>
        </v-fade-transition>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { INDIVIDUAL_KINDS, initUserIndividual, initUserOrganization, USER_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

import TableRow from "@/components/dumb/table-row.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";

import DialogCertExample from "@/components/client/join/dialog-cert-example.vue";
import EquipmentAgreementDownload from "@/components/client/join/equipment-agreement-download.vue";

import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,

        TableRow,
        TablePrimary,

        DialogCertExample,
        EquipmentAgreementDownload,

        VPhoneField,
        VEmailField,
    },
    data: () => ({
        form: initUserIndividual(),

        USER_TYPES,

        loading: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push({
                        path: "/login",
                        params: this.$router.params,
                    });
                    return;
                }

                var { user } = await api.v1.me.get();

                if (user?.type == USER_TYPES.INDIVIDUAL) {
                    this.form = initUserIndividual(user);
                }
                if (user?.type == USER_TYPES.ORGANIZATION) {
                    this.form = initUserOrganization(user);
                }
            } finally {
                this.loading = false;
            }
        },

        emit() {
            if (this.form.type == USER_TYPES.INDIVIDUAL) {
                this.form = initUserIndividual(this.form);
            }
            if (this.form.type == USER_TYPES.ORGANIZATION) {
                this.form = initUserOrganization(this.form);
            }
        },

        async save() {
            try {
                this.validate();
            } catch (error) {
                alert(error.message);
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                ////////////////////////////////////////////////////////////////
                // 폼데이터 정돈
                ////////////////////////////////////////////////////////////////
                let { ...form } = this.form;
                if (this.form.type == USER_TYPES.INDIVIDUAL) {
                    form = initUserIndividual(this.form);
                }
                if (this.form.type == USER_TYPES.ORGANIZATION) {
                    form = initUserOrganization(this.form);
                }

                ////////////////////////////////////////////////////////////////
                // 파일변수 선언
                ////////////////////////////////////////////////////////////////
                let { _policies = [], policies = [] } = form.blacksmith.insurance;
                delete form.blacksmith.insurance.policies;

                let { _equipmentUseConsent, equipmentUseConsent } = form.blacksmith;
                delete form.blacksmith.equipmentUseConsent;

                let { _businessRegistration, businessRegistration } = form;
                delete form.businessRegistration;

                ////////////////////////////////////////////////////////////////
                // 회원 업데이트
                ////////////////////////////////////////////////////////////////
                const { user } = await api.v1.me.put(form);

                ////////////////////////////////////////////////////////////////
                // 파일 처리
                ////////////////////////////////////////////////////////////////

                ////////////////////
                // 보험증서
                ////////////////////
                if (policies.length) {
                    // 추가파일 생성처리
                    policies = await Promise.all(policies.map(async (file, index) => (file instanceof File ? (await api.v1.common.files.post({ path: "insurance-policies", index }, file))?.file : file)));

                    // 제거파일 삭제처리
                    await Promise.all(_policies.filter((_policy) => !policies.some((item) => item?._id == _policy)).map(async (_id) => await api.v1.common.files.delete({ _id })));
                }
                // 식별코드 업데이트
                _policies = policies.map((item) => item?._id).filter((_id) => _id);
                ////////////////////

                ////////////////////
                // 장비이용 동의서
                ////////////////////
                if (equipmentUseConsent) {
                    // 추가파일 생성처리
                    equipmentUseConsent = equipmentUseConsent instanceof File ? (await api.v1.common.files.post({ path: "equipment-use-consent" }, equipmentUseConsent))?.file : equipmentUseConsent;

                    // 제거파일 삭제처리
                    if (!!_equipmentUseConsent && _equipmentUseConsent != equipmentUseConsent?._id) await api.v1.common.files.delete({ _id: _equipmentUseConsent });
                }
                // 식별코드 업데이트
                _equipmentUseConsent = equipmentUseConsent?._id;
                ////////////////////

                ////////////////////
                // 사업자등록증
                ////////////////////
                if (businessRegistration) {
                    // 추가파일 생성처리
                    businessRegistration = businessRegistration instanceof File ? (await api.v1.common.files.post({ path: "equipment-use-consent" }, businessRegistration))?.file : businessRegistration;

                    // 제거파일 삭제처리
                    if (!!_businessRegistration && _businessRegistration != businessRegistration?._id) await api.v1.common.files.delete({ _id: _businessRegistration });
                }
                // 식별코드 업데이트
                _businessRegistration = businessRegistration?._id;
                ////////////////////

                ////////////////////////////////////////////////////////////////
                // 회원 업데이트
                ////////////////////////////////////////////////////////////////
                await api.v1.me.put({
                    _businessRegistration,

                    blacksmith: {
                        ...(user?.blacksmith || {}),

                        _equipmentUseConsent,
                        insurance: {
                            ...(user?.blacksmith?.insurance || {}),
                            _policies,
                        },
                    },
                });

                alert("수정되었습니다");
                this.$router.go();
            } finally {
                this.loading = false;
            }
        },

        validate() {
            if (!this.form.username) throw new Error("아이디를 입력해주세요.");

            if (!this.form.name) throw new Error("이름을 입력해주세요.");
            if (!this.form.phone) throw new Error("연락처를 입력해주세요.");
            if (!this.form.email) throw new Error("이메일을 입력해주세요.");

            if (this.form.type == USER_TYPES.ORGANIZATION) {
                if (!this.form.companyName) throw new Error("회사명을 입력해주세요.");
                if (!this.form.businessNumber) throw new Error("사업자등록번호를 입력해주세요.");
                if (!this.form.businessRegistration) throw new Error("사업자등록증을 업로드해주세요.");
            }
        },

        pullInsurancePolicy(index) {
            this.form.blacksmith.insurance.policies.splice(index, 1);
            this.emit();
        },
    },
};
</script>
